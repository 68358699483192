import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ViewServiceAssetNetworkList() {
  const navigate = useNavigate();
  const [serviceAssetNetworkList, setServiceAssetNetworkList] = useState<any[]>(
    [],
  );
  const [creationForm] = Form.useForm();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);
  const [toDeleteId, setToDeleteId] = useState<any>(undefined);
  const handleCancelDelete = useCallback(() => {
    setToDeleteId(undefined);
    setShowDeleteConfirmModal(false);
  }, []);
  const [assetNetworkOptions, setAssetNetworkOptions] = useState<any[]>([]);
  const [serviceOptions, setServiceOptions] = useState<any[]>([]);
  const getServiceAssetNetworkList = useCallback(() => {
    setTableIsLoading(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/asset/service-asset-network",
      withCredentials: true,
    })
      .then((res) => {
        setServiceAssetNetworkList(res.data.data);
        setCanEdit(res.data.canEdit);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setTableIsLoading(false);
      });
  }, [navigate]);
  const getAssetNetworkList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/networks",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkOptions(res.data);
    });
  }, []);
  const getServicelist = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/service",
      withCredentials: true,
    }).then((res) => {
      setServiceOptions(
        res.data.services?.map((row: any) => ({
          label: row.name,
          value: row.id,
          key: row.id,
        })),
      );
    });
  }, []);
  useEffect(() => {
    getAssetNetworkList();
    getServicelist();
    getServiceAssetNetworkList();
  }, [getAssetNetworkList, getServiceAssetNetworkList, getServicelist]);

  const createServiceAssetNetworkMap = useCallback(() => {
    creationForm.validateFields().then(() => {
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/asset/service-asset-network",
        data: {
          ...creationForm.getFieldsValue(),
        },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Service Asset Network Map Created Successfully");
          creationForm.resetFields();
          setShowCreationModal(false);
          getServiceAssetNetworkList();
        })
        .catch((err) => {
          toast.error("Duplicate Service Asset Network Pair");
          if (err.response.status === 403) {
            navigate("/login");
          }
        });
    });
  }, [creationForm, navigate, getServiceAssetNetworkList]);

  const confirmDelete = useCallback(
    (id: number) => {
      axios({
        method: "Delete",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/asset/service-asset-network",
        data: {
          id: id,
        },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Deleted Successfully");
          getServiceAssetNetworkList();
          setToDeleteId(undefined);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setShowDeleteConfirmModal(false);
        });
    },
    [getServiceAssetNetworkList, navigate],
  );
  return (
    <Content id="view-service-asset-network">
      <Row justify={"end"}>
        <Button
          type="primary"
          className="dcl-btn-toggle"
          disabled={!canEdit}
          onClick={() => {
            setShowCreationModal(true);
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          New Service Asset Network Map
        </Button>
      </Row>
      {tableIsLoading ? (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : (
        <Table
          dataSource={serviceAssetNetworkList}
          columns={[
            {
              title: "Service Name",
              dataIndex: "serviceName",
            },
            {
              title: "Asset Network",
              dataIndex: "assetNetworkName",
            },
            {
              title: "Value",
              dataIndex: "value",
            },
            {
              title: "Action",
              dataIndex: "id",
              render: (_: any, { id }: any) => (
                <Button
                  disabled={!canEdit}
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    setShowDeleteConfirmModal(true);
                    setToDeleteId(id);
                  }}
                >
                  <FontAwesomeIcon
                    className="delete-icon"
                    icon={"fa-regular fa-square-minus" as IconProp}
                  />
                </Button>
              ),
            },
          ]}
        />
      )}
      <Modal
        open={showCreationModal}
        width="80%"
        footer={null}
        onCancel={() => {
          setShowCreationModal(false);
          creationForm.resetFields();
        }}
        onClose={() => {
          setShowCreationModal(false);
          creationForm.resetFields();
        }}
      >
        <Card style={{ marginTop: "20px" }}>
          <Form
            form={creationForm}
            labelCol={{
              style: {
                fontWeight: "bold",
              },
            }}
          >
            <Form.Item
              label="Service"
              required
              name={"serviceId"}
              rules={[
                {
                  required: true,
                  message: "Please select service!",
                },
              ]}
            >
              <Select
                options={serviceOptions}
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Service"
              />
            </Form.Item>
            <Form.Item
              label="Asset Network"
              required
              name={"assetNetworkId"}
              rules={[
                {
                  required: true,
                  message: "Please select asset netowrk!",
                },
              ]}
            >
              <Select
                options={assetNetworkOptions}
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Asset Network"
              />
            </Form.Item>
            <Form.Item
              label="Value"
              required
              name={"value"}
              rules={[
                {
                  required: true,
                  message: "Please input service value!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
          <Row justify={"center"}>
            <Button
              type="primary"
              className="submit-button primary-button"
              onClick={() => {
                createServiceAssetNetworkMap();
              }}
            >
              Create Service Asset Network Map
            </Button>
          </Row>
        </Card>
      </Modal>
      <Modal
        open={showDeleteConfirmModal}
        width="80%"
        footer={[
          <Button key="back" onClick={handleCancelDelete}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            onClick={() => confirmDelete(toDeleteId)}
            danger
            type="primary"
          >
            Confirm
          </Button>,
        ]}
        onCancel={() => {
          handleCancelDelete();
        }}
        onClose={() => {
          handleCancelDelete();
        }}
      >
        <b style={{ color: "white" }}>
          Are you sure to delete this service asset network map?
        </b>
      </Modal>
    </Content>
  );
}
