import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

export default function ComplianceTransactionScan() {
  // Function to format and display alertsDetail?.alerts
  const [, copy] = useCopyToClipboard();

  const formatArray = (type: string, array: any[], level = 0) => {
    // Helper function to format key-value pairs
    const formatEntries = (entries: any) =>
      entries.map(([key, value]: any) => {
        const formattedKey = (key.charAt(0).toUpperCase() + key.slice(1))
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2"); // Capitalize first letter
        const indent = { paddingLeft: `${level * 20}px` }; // Indentation for nested levels

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Recursive handling for nested objects
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong>
              </div>
              {formatArray(type, [value], level + 1)}
            </div>
          );
        } else if (Array.isArray(value)) {
          // Check if array contains objects
          const isArrayOfObjects = value.every(
            (item) => typeof item === "object" && item !== null,
          );

          if (isArrayOfObjects) {
            // Handle array of objects with proper prefix and consistent indentation
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong>
                </div>
                <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
                  {value.map((item, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <div>
                        <strong>{index + 1}.</strong>{" "}
                        {formatArray(type, [item], level + 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else {
            // Handle simple arrays
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong> [{value.join(", ")}]
                </div>
              </div>
            );
          }
        } else {
          // Handle primitive values
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong> {value || "N/A"}
              </div>
            </div>
          );
        }
      });

    if (!array || array.length === 0) {
      return <div>No {type}s.</div>;
    }

    return (
      <>
        {array.map((alert: any, index: number) => (
          <div key={index} style={{ marginBottom: "16px" }}>
            <strong>
              {type.charAt(0).toUpperCase() + type.slice(1)} {index + 1}:
            </strong>
            <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
              {formatEntries(Object.entries(alert))}
            </div>
          </div>
        ))}
      </>
    );
  };

  const [scanForm] = Form.useForm();
  const [serviceAssetNetworkMap, setServiceAssetNetworkMap] = useState<any[]>(
    [],
  );
  const [assetOptions, setAssetOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState<any>(undefined);
  const getServiceAssetNetworkList = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/asset/service-asset-network",
      withCredentials: true,
    })
      .then((res) => {
        setServiceAssetNetworkMap(
          res.data.data?.map((row: any) => ({
            label: `${row.value} (${row.serviceName})`,
            value: row.id,
            key: row.id,
          })),
        );
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  const getAssetList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          key: asset.id,
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      options.sort((a, b) => a.label.localeCompare(b.label));
      setAssetOptions(options);
    });
  }, []);
  useEffect(() => {
    getAssetList();
    getServiceAssetNetworkList();
  }, [getAssetList, getServiceAssetNetworkList]);
  const scanTransaction = useCallback(() => {
    scanForm
      .validateFields()
      .then(() => {
        setIsSending(true);
        setScanResult(undefined);
        axios({
          method: "Post",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/util/compliance/scan/transaction",
          data: {
            ...scanForm.getFieldsValue(),
          },
          withCredentials: true,
        })
          .then((res) => {
            setScanResult(res.data?.scanJSON);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              navigate("/login");
            }
          })
          .finally(() => {
            setIsSending(false);
          });
      })
      .catch(() => {});
  }, [scanForm, navigate]);
  const copyExternalId = (externalId: string) => {
    copy(externalId);
    toast.success(`${externalId} copied to clipboard`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <Content id="compliance-transaction-scan">
      <Row align="middle" justify="space-around" style={{ marginTop: "2em" }}>
        <Col span={22}>
          <Form
            form={scanForm}
            labelCol={{
              style: {
                fontWeight: "bold",
              },
            }}
            wrapperCol={{
              style: {
                maxWidth: "500px",
              },
            }}
          >
            <Form.Item
              label="Transaction Hash"
              required
              name={"txHash"}
              rules={[
                {
                  required: true,
                  message: "Please input transaction hash!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Receiving Address"
              required
              name={"toAddress"}
              rules={[
                {
                  required: true,
                  message: "Please input to Address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Asset"
              name="assetId"
              rules={[
                {
                  required: true,
                  message: "Please select asset!",
                },
              ]}
            >
              <Select
                options={assetOptions}
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Asset"
              />
            </Form.Item>
            <Form.Item
              label="Network"
              name="serviceAssetNetworkMapId"
              rules={[
                {
                  required: true,
                  message: "Please select network!",
                },
              ]}
            >
              <Select
                options={serviceAssetNetworkMap}
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Network"
              />
            </Form.Item>
            <Form.Item
              label="Direction"
              name="direction"
              rules={[
                {
                  required: true,
                  message: "Please choose direction!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="sent">Sender</Radio>
                <Radio value="received">Receiver</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Row justify={"center"}>
            <Button
              type="primary"
              className="submit-button primary-button"
              onClick={() => {
                scanTransaction();
              }}
              loading={isSending}
            >
              Scan Transaction
            </Button>
          </Row>
          {scanResult ? (
            <Card
              style={{
                width: "100%",
                height: "100%",
                marginTop: "2em",
                marginBottom: "2em",
                maxHeight: "80vh",
                overflow: "auto",
              }}
              title={
                <Row justify="start" align="middle">
                  <b>External ID: </b> &nbsp;
                  {scanResult?.externalId}
                  <Button
                    onClick={() => {
                      copyExternalId(scanResult?.externalId);
                    }}
                    style={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
                  </Button>
                </Row>
              }
            >
              <Row gutter={[10, 5]}>
                <Col span={12} key="txn-scan-col-1">
                  
                  <Row>
                    <b>Updated At:</b> &nbsp;
                    {scanResult?.transferDetail?.updatedAt}
                  </Row>
                  <Divider>
                    <b>Alerts</b>
                  </Divider>
                  {formatArray("alert", scanResult?.alertsDetail?.alerts || [])}
                  <Divider>
                    <b>Exposures</b>
                  </Divider>

                  {scanResult?.exposureDetail?.direct?.name ? (
                    <React.Fragment>
                      <Row>
                        <b>Name: </b> &nbsp;
                        {scanResult?.exposureDetail?.direct?.name}
                      </Row>
                      {scanResult?.exposureDetail?.direct?.categoryId ? (
                        <Row>
                          <b>Category Id: </b>&nbsp;
                          {scanResult?.exposureDetail?.direct?.categoryId}
                        </Row>
                      ) : undefined}
                    </React.Fragment>
                  ) : (
                    <Row>No Exposures</Row>
                  )}
                </Col>
                <Col span={12} key="txn-scan-col-2">
                  <Divider style={{ marginTop: "0px" }}>
                    <b>Summary</b>
                  </Divider>
                  {Object.entries(scanResult?.transferDetail).map(
                    ([key, value]: any) => (
                      <div key={key} style={{ marginBottom: "8px" }}>
                        <div
                          style={{
                            wordBreak: "break-word",
                          }}
                        >
                          <strong>
                            {(key.charAt(0).toUpperCase() + key.slice(1))
                              .replace(/([a-z])([A-Z])/g, "$1 $2")
                              .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")}
                            :&nbsp;
                          </strong>
                          {value}
                        </div>
                      </div>
                    ),
                  )}
                </Col>
              </Row>
            </Card>
          ) : undefined}
        </Col>
      </Row>
    </Content>
  );
}
