import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "antd";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

export default function ViewRiskRawData({ rawData }: any) {
  const [, copy] = useCopyToClipboard();
  const formatJson = (obj: any, level: number = 0): JSX.Element => {
    const entries = Object.entries(obj);

    // Separate entries into non-array and array entries
    const nonArrayEntries = entries.filter(
      ([_, value]) => !Array.isArray(value),
    );
    const arrayEntries = entries.filter(([_, value]) => Array.isArray(value));

    // Helper function to format key-value pairs
    const formatEntries = (entries: [string, any][]) =>
      entries.map(([key, value]) => {
        const formattedKey = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize first letter
        const indent = { paddingLeft: `${level * 20}px` }; // Indentation for nested levels

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Recursive handling for nested objects
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong>
              </div>
              {formatJson(value, level + 1)}
            </div>
          );
        } else if (Array.isArray(value)) {
          // Check if array contains objects
          const isArrayOfObjects = value.every(
            (item) => typeof item === "object" && item !== null,
          );

          if (isArrayOfObjects) {
            // Handle array of objects with proper prefix and consistent indentation
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong>
                </div>
                <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
                  {value.map((item, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <div>
                        <strong>{index + 1}.</strong>{" "}
                        {formatJson(item, level + 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else {
            // Handle simple arrays
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong> [{value.join(", ")}]
                </div>
              </div>
            );
          }
        } else {
          // Handle primitive values
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong> {value}
              </div>
            </div>
          );
        }
      });

    return (
      <>
        {formatEntries(nonArrayEntries)}
        {formatEntries(arrayEntries)}
      </>
    );
  };

  return (
    <Card
      title={<b>Risk Data</b>}
      id={"view-risk-raw-data"}
      style={{
        width: "100%",
        marginTop: "2em",
        maxHeight: "80vh",
        overflow: "auto",
      }}
      extra={
        <Button
          onClick={() => {
            copy(rawData);
            toast.success("Copied!");
          }}
        >
          <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
          Raw JSON Data
        </Button>
      }
    >
      <div>{formatJson(JSON.parse(rawData))}</div>
    </Card>
  );
}
