import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewAssetList from "./ViewAssetList";
import ViewServiceAssetNetworkList from "./ViewServiceAssetNetworkList";

export default function ViewAssetSettingContainer() {
  const navigate = useNavigate();
  const { tabName = "" } = useParams();
  const [assetFilter, setAssetFilter] = useState<any>();

  return (
    <Content id="view-asset-setting-container">
      <Tabs
        type="card"
        defaultActiveKey={tabName}
        onChange={(key) => {
          navigate("/bo/settings/assets/" + key);
        }}
        items={[
          {
            label: "Assets",
            key: "",
            children: (
              <ViewAssetList
                filter={assetFilter}
                onFilterChange={(filter: any) => {
                  setAssetFilter(filter);
                }}
              />
            ),
          },
          {
            label: "Service Asset Network Map",
            key: "service-asset-network-map",
            children: <ViewServiceAssetNetworkList />,
          },
        ]}
      />
    </Content>
  );
}
